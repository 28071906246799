import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import SideBar from '../components/SideBar';
import CouponCard from "../components/CouponCard" // Import your CouponCard component // Import the CSS file for styling
import { useNavigate, useParams } from 'react-router-dom';
import { getCouponData } from '../services/getCouponData';
import Swal from 'sweetalert2';
import PageLayout from '../Layout/Layout';

export default function CouponData() {

    const [coupon, setCoupon] = useState(null)

    const { id } = useParams();
    const navigate = useNavigate();
    // console.log(id);

    useEffect(() => {
        console.log("called");
        handleCouponData(id);
    }, [])

    const handleCouponData = async () => {
        console.log("Api function called")
        const response = await getCouponData(id);
        console.log("///", response);
        if (response.status === 200) {
            setCoupon(response?.data?.data);
        } else if (response?.response?.status === 401) {
            navigate("/login");
        } else {
            Swal.fire({
                title: '',
                text: 'Error while fetching coupon details',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Got it',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate("/main");
                }
            });
        }
    }


    // Sample coupon data
    const coupons = [
        {
            title: 'Coupon 1',
            description: 'Description for Coupon 1',
            discount: '25% off',
            expirationDate: '2023-12-31',
            // Add more details here
        },
        {
            title: 'Coupon 2',
            description: 'Description for Coupon 2',
            discount: '15% off',
            expirationDate: '2023-11-30',
            // Add more details here
        },
        // Add more coupon data as needed
    ];

    return (
        <PageLayout>
            <div>
                <button type='button' style={{margin:"0px 0px 0px 65px"}} className='btn btn-warning mt-3' onClick={() => {
                    navigate(-1)
                }}>Go Back</button>
                <div className="mc-main-container coupon_container d-flex justify-content-start">
                    <CouponCard coupon={coupon && coupon} />
                </div>
            </div>
        </PageLayout>
    );
}
