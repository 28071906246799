
import { useState } from "react";
import SideBar from "../components/SideBar";
import Content from "../components/Content"
import Coupans from "./Coupans";
import Section from "./Section";
import Navbar from "../components/Navbar";
import PageLayout from "../Layout/Layout";
export default function Main() {
  const [drawerItem, setDrawerItem] = useState();
  const [activeItem, setActiveItem] = useState('Home');
  return (
    <>
      <PageLayout >

        <div className="row coupen_row" style={{ display: "flex" }}>
          {/* <div className="sidebar_div" style={{ flexBasis: "15%" }}> */}
            {/* <SideBar setDrawerItem= {setDrawerItem} setActiveItem ={setActiveItem} activeItem={activeItem}/> */}
          </div>
          {/* <div className="col-md-1 col-lg-1 col-sm-1"></div> */}
          <div className="table_div" style={{ flexBasis: "85%" }}>
            {activeItem === "Home" ? <Content /> : activeItem === "Coupons" ? <Coupans /> : <Section />}
          </div>
          {/* <div className="col-md-1"></div> */}
        {/* </div> */}
        <style>

        </style>
      </PageLayout>
    </>

  );
}
