import axios from "axios";
import { baseUrl } from "../constant";

export const getCouponData = async (id) => {
    try {
        const response = await axios.get(`${baseUrl}/api/admin/coupons/details?id=${id}`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })
        return response;
    } catch (err) {
        return err;
    }
}